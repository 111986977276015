import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    ButtonGroup,
    Button,
    Typography,
    CircularProgress
} from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';

const formatDate = (date) => {
    return date.toLocaleDateString('en-US', {
        weekday: 'long',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    }).replace(/(\d+)\/(\d+)\/(\d+)/, '$1/$2/$3');
};

const OptimizationDialog = ({
    open,
    onClose,
    loadBalancer,
    optimizationResults,
    loading,
    errorMessage,
    onStopOptimizing
}) => {
    if (!loadBalancer) return null;

    const timeWindows = optimizationResults ? optimizationResults.time_windows : [];

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 2 }}>
                <Typography variant="h6">Optimization Recommendations</Typography>
                {optimizationResults && (
                    <Button
                        variant="outlined"
                        color="error"
                        onClick={onStopOptimizing}
                        sx={{
                            textTransform: 'none',
                            borderRadius: 1,
                            fontSize: '0.875rem'
                        }}
                    >
                        STOP OPTIMIZING
                    </Button>
                )}
            </DialogTitle>
            <DialogContent>
                <Paper elevation={1} sx={{ p: 3, mb: 3, bgcolor: 'background.paper' }}>
                    <Typography variant="subtitle1" sx={{ mb: 1 }}>Load Balancer</Typography>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'text.primary' }}>{loadBalancer.name}</Typography>
                    <Typography variant="caption" sx={{ display: 'block', color: 'text.secondary' }}>
                        {loadBalancer.arn}
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                        <Box>
                            <Typography variant="subtitle1">Cost Savings</Typography>
                            <Typography variant="h6" sx={{
                                fontWeight: 'bold',
                                color: optimizationResults && optimizationResults.cost_savings >= 0 ? '#2e7d32' : '#d32f2f'
                            }}>
                                {optimizationResults ? `${optimizationResults.cost_savings} USD` : '-'}
                            </Typography>
                        </Box>
                        <Box sx={{ mr: 4 }}>
                            <Typography variant="subtitle1">Date</Typography>
                            <Typography>{optimizationResults ? formatDate(new Date(optimizationResults.date)) : '-'}</Typography>
                        </Box>
                    </Box>
                </Paper>

                {loading ? (
                    <TableContainer component={Paper} sx={{ mt: 1 }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Optimization Window</TableCell>
                                    <TableCell>Recommendation</TableCell>
                                    <TableCell>Act on Recommendation?</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {[1, 2, 3].map((row) => (
                                    <TableRow key={row}>
                                        <TableCell>
                                            <Skeleton variant="text" />
                                        </TableCell>
                                        <TableCell>
                                            <Skeleton variant="text" />
                                        </TableCell>
                                        <TableCell>
                                            <Box sx={{ display: 'flex'}}>
                                                <Skeleton variant="rectangular" width={80} height={32} />
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : errorMessage ? (
                    <Typography variant="body1" color="error" sx={{ mt: 3, textAlign: 'center' }}>
                        {errorMessage}
                    </Typography>
                ) : optimizationResults ? (
                    <TableContainer component={Paper} sx={{ mt: 1 }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Optimization Window</TableCell>
                                    <TableCell>Recommendation</TableCell>
                                    <TableCell>Act on Recommendation?</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {timeWindows.map((window, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{window.time}</TableCell>
                                        <TableCell>
                                            <Box sx={{ display: 'flex', alignItems: 'center', color: window.action === 'Scale Down' ? '#d32f2f' : '#2e7d32' }}>
                                                {window.action === 'Scale Down' ? <TrendingDownIcon sx={{ mr: 1 }} /> : <TrendingUpIcon sx={{ mr: 1 }} />}
                                                {window.action}
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <ButtonGroup variant="outlined" size="small">
                                                <Button
                                                    sx={{
                                                        ...(window.status === 'yes' && {
                                                            backgroundColor: '#2e7d32',
                                                            color: 'white',
                                                            '&:hover': {
                                                                backgroundColor: '#1b5e20'
                                                            }
                                                        })
                                                    }}
                                                >
                                                    Yes
                                                </Button>
                                                <Button
                                                    sx={{
                                                        ...(window.status === 'no' && {
                                                            backgroundColor: '#d32f2f',
                                                            color: 'white',
                                                            '&:hover': {
                                                                backgroundColor: '#c62828'
                                                            }
                                                        })
                                                    }}
                                                >
                                                    No
                                                </Button>
                                            </ButtonGroup>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Typography variant="body1" sx={{ mt: 3, textAlign: 'center' }}>
                        Your recommendations are being generated. Please check again after some time.
                    </Typography>
                )}
            </DialogContent>
        </Dialog>
    );

};

export default OptimizationDialog;
