import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    TimeScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { format, subDays, subMonths, subYears, isWithinInterval } from 'date-fns';
import { awsService } from 'src/services/awsService';
import annotationPlugin from 'chartjs-plugin-annotation';

// Register necessary Chart.js components
ChartJS.register(
    TimeScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    annotationPlugin
);

export default function Cost({ awsmoGroupId, token, granularity }) {
    const [costData, setCostData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const timeRanges = useMemo(() => ({
        '7d': { days: 7 },
        '1m': { months: 1 },
        '1y': { years: 1 }
    }), []);

    const { startDate, endDate } = useMemo(() => {
        const end = new Date();
        const start = (() => {
            switch (granularity) {
                case '7d':
                    return subDays(end, timeRanges['7d'].days);
                case '1m':
                    return subMonths(end, timeRanges['1m'].months);
                case '1y':
                    return subYears(end, timeRanges['1y'].years);
                default:
                    return subDays(end, timeRanges['7d'].days);
            }
        })();
        return { startDate: start, endDate: end };
    }, [granularity, timeRanges]);

    const processCostData = useCallback((data) => {
        const filteredData = data.filter(item => item.awsmo_group === awsmoGroupId);
        const sortedData = filteredData.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
        const dateFilteredData = sortedData.filter(item =>
            isWithinInterval(new Date(item.timestamp), { start: startDate, end: endDate })
        );
        return dateFilteredData.map(item => ({
            x: new Date(item.timestamp),
            y: item.total_per_hour_cost,
            details: item.data
        }));
    }, [awsmoGroupId, startDate, endDate]);

    const fetchCostData = useCallback(async () => {
        setIsLoading(true);
        setError(null);
        try {
            const data = await awsService.fetchAwsCost(token);
            const processedData = processCostData(data);
            setCostData(processedData);
        } catch (error) {
            console.error('Error fetching cost data:', error);
            setError('Failed to load cost data.');
        } finally {
            setIsLoading(false);
        }
    }, [token, processCostData]);

    useEffect(() => {
        if (awsmoGroupId) {
            fetchCostData();
        } else {
            setCostData([]);
            setIsLoading(false);
        }
    }, [awsmoGroupId, granularity, fetchCostData]);

    const [minDate, maxDate] = useMemo(() => {
        if (costData.length === 0) return [new Date(), new Date()];
        const dates = costData.map(d => d.x);
        return [new Date(Math.min(...dates)), new Date(Math.max(...dates))];
    }, [costData]);

    const getMaxValue = useCallback(() => {
        if (costData.length === 0) return 100;
        const maxCost = Math.max(...costData.map(d => d.y));
        return maxCost * 1.1;
    }, [costData]);

    const chartData = useMemo(() => ({
        datasets: [{
            label: 'Total Cost per Hour (USD)',
            data: costData,
            borderColor: 'rgb(75, 192, 192)',
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            tension: 0.4,
            fill: true,
            pointRadius: 3,
            pointHoverRadius: 6,
            pointBackgroundColor: 'rgb(75, 192, 192)',
        }]
    }), [costData]);

    const chartOptions = useMemo(() => ({
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
            intersect: false,
            mode: 'index',
        },
        plugins: {
            legend: { display: true },
            tooltip: {
                callbacks: {
                    title: (context) => {
                        const date = new Date(context[0].parsed.x);
                        return format(date, granularity === '7d' ? 'MMM dd, yyyy HH:mm' : 'MMM dd, yyyy');
                    },
                    label: (context) => {
                        const dataPoint = context.raw;
                        const detailsArray = ["Instances:"];
            
                        const lbArn = Object.keys(dataPoint.details)[0];
                        const tgArn = Object.keys(dataPoint.details[lbArn])[0];
                        const instances = dataPoint.details[lbArn][tgArn].instances;
            
                        if (instances && Array.isArray(instances)) {
                            instances.forEach(instanceId => {
                                detailsArray.push('\u2022 ' + instanceId);
                            });
                        }
            
                        return detailsArray;
                    },
                    footer: (context) => {
                        const dataPoint = context[0].raw;
                        const lbArn = Object.keys(dataPoint.details)[0];
                        const tgArn = Object.keys(dataPoint.details[lbArn])[0];

                        const instanceType = dataPoint.details[lbArn][tgArn].instance_type;

                        return `Instance Type: ${instanceType}`;
                    },
                },
                displayColors: false,
            },
            annotation: {
                annotations: [
                    {
                        type: 'line',
                        mode: 'horizontal',
                        scaleID: 'y',
                        value: getMaxValue(),
                        borderColor: 'rgba(255, 99, 132, 0.5)',
                        borderWidth: 2,
                        borderDash: [6, 6],
                        label: {
                            enabled: true,
                            content: 'Upper Threshold',
                            position: 'end',
                            backgroundColor: 'rgba(255, 99, 132, 0.5)',
                            color: '#fff',
                            padding: 4,
                            font: {
                                size: 12,
                            }
                        }
                    }
                ]
            }
        },
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: granularity === '7d' ? 'hour' : 'day',
                    displayFormats: {
                        hour: 'MMM dd, HH:mm',
                        day: 'MMM dd'
                    }
                },
                title: {
                    display: true,
                    text: 'Date'
                },
                ticks: {
                    maxRotation: 0,
                    autoSkip: true,
                    maxTicksLimit: 7
                },
                min: minDate,
                max: maxDate
            },
            y: {
                title: {
                    display: true,
                    text: 'Cost per Hour (USD)'
                },
                ticks: {
                    callback: (value) => value
                },
                suggestedMin: 0,
                suggestedMax: getMaxValue()
            }
        },
    }), [granularity, getMaxValue, minDate, maxDate, costData]);

    if (isLoading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 'calc(100vh - 300px)'
                }}
            >
                <CircularProgress />
                <Typography variant="body1" sx={{ ml: 2 }}>
                    Loading cost data...
                </Typography>
            </Box>
        );
    }

    if (error) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 'calc(100vh - 300px)'
                }}
            >
                <Typography variant="body1" color="error">
                    {error}
                </Typography>
            </Box>
        );
    }

    if (!isLoading && costData.length === 0) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 'calc(100vh - 300px)'
                }}
            >
                <Typography variant="body1">
                    No cost data available for the selected load balancer.
                </
                Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ display: 'flex', gap: 3, height: 'calc(100vh - 300px)' }}>
            <Box sx={{ flex: 1, bgcolor: 'white', borderRadius: 1, p: 2 }}>
                <Line data={chartData} options={chartOptions} />
            </Box>
        </Box>
    );
}
