import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    Button,
    IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const VerificationModal = ({ open, onClose, onConfirm }) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h6">Confirm Optimization</Typography>
                <IconButton onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Typography variant="body1">
                    This action will allow Awsmo to scale up or down instances under the given Load Balancer. Please proceed carefully.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary" variant="outlined">
                    Cancel
                </Button>
                <Button onClick={onConfirm} color="primary" variant="contained">
                    Yes, Proceed
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default VerificationModal;