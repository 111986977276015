import React, { useState } from 'react';
import { Modal, Button, Tabs, Tab, Form, Row, Col, Card, Dropdown } from 'react-bootstrap';
import toast, { Toaster } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { awsService } from 'src/services/awsService';
import { authService } from 'src/services/authService';
import 'src/style/Dashboard.css';

const ProfileModal = ({ show, onHide }) => {
    const [activeTab, setActiveTab] = useState('invite');

    // State for Invite User
    const [inviteEmail, setInviteEmail] = useState('');
    const [isInviting, setIsInviting] = useState(false);
    const [permissionLevel, setPermissionLevel] = useState('0');

    // State for Reset Password
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isResetting, setIsResetting] = useState(false);

    // Email Regex for Validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Accessing Redux state
    const user = useSelector((state) => state.user);
    const accountId = useSelector((state) => state.awsAccount.accountId);
    const rootUserEmail = useSelector((state) => state.org.rootUserEmail);
    const token = useSelector((state) => state.user.token);

    const isRootUser = user.user === rootUserEmail;

    // Handlers for Invite User
    const handleInvite = async (e) => {
        e.preventDefault();
        if (!emailRegex.test(inviteEmail)) {
            toast.error('Please enter a valid email address.');
            return;
        }
        setIsInviting(true);
        try {
            const response = await authService.sendInvite(inviteEmail, permissionLevel, accountId, token);
            if (response === 201) {
                toast.success('User invited successfully!');
                setInviteEmail('');
                setPermissionLevel('0');
            } else {
                toast.error('Failed to invite user. Please try again.');
            }
        } catch (error) {
            console.error(error);
            toast.error('Failed to invite user. Please try again.');
        } finally {
            setIsInviting(false);
        }
    };

    // Handlers for Reset Password
    const handleResetPassword = async (e) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            toast.error('New passwords do not match.');
            return;
        }

        if (newPassword.length < 6) {
            toast.error('New password must be at least 6 characters long.');
            return;
        }

        setIsResetting(true);
        try {
            await awsService.resetPassword(currentPassword, newPassword);
            toast.success('Password reset successfully!');
            setCurrentPassword('');
            setNewPassword('');
            setConfirmPassword('');
            onHide();
        } catch (error) {
            console.error(error);
            toast.error('Failed to reset password. Please check your current password and try again.');
        } finally {
            setIsResetting(false);
        }
    };
    return (
        <>
            <Toaster position="top-center" />
            <Modal show={show} onHide={onHide} size="lg" aria-labelledby="profile-modal-title" centered>
                <Modal.Header closeButton>
                    <Modal.Title id="profile-modal-title">Profile Settings</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card className="mb-4">
                        <Card.Body>
                            <Row>
                                <Col md={4}><strong>Account ID:</strong></Col>
                                <Col md={8}>{accountId || 'N/A'}</Col>
                            </Row>
                            <Row className="mt-2">
                                <Col md={4}><strong>User Email:</strong></Col>
                                <Col md={6}>{user?.user || 'N/A'}</Col>
                            </Row>
                            {isRootUser ? (
                                <Row className="mt-2">
                                    <Col md={4}><strong>User Type:</strong></Col>
                                    <Col md={8}>ROOT</Col>
                                </Row>
                            ) : (
                                <>
                                    <Row className="mt-2">
                                        <Col md={4}><strong>Root User Email:</strong></Col>
                                        <Col md={8}>{rootUserEmail || 'N/A'}</Col>
                                    </Row>
                                    <Row className="mt-2">
                                        <Col md={4}><strong>User Type:</strong></Col>
                                        <Col md={8}>NON ROOT</Col>
                                    </Row>
                                </>
                            )}
                        </Card.Body>
                    </Card>
                    <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)} className="mb-3">
                        <Tab eventKey="invite" title="Invite User">
                            <Form onSubmit={handleInvite}>
                                <Row>
                                    <Col md={8}>
                                        <Form.Group controlId="inviteEmail">
                                            <Form.Label>Email Address</Form.Label>
                                            <Form.Control
                                                type="email"
                                                placeholder="Enter user's email"
                                                value={inviteEmail}
                                                onChange={(e) => setInviteEmail(e.target.value)}
                                                required
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Label>Permission Level</Form.Label>
                                        <Dropdown>
                                            <Dropdown.Toggle variant="light" id="dropdown-permission-level" style={{ width: '50%' }}>
                                                {permissionLevel}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu style={{ width: '100%' }}>
                                                {[...Array(6).keys()].map(level => (
                                                    <Dropdown.Item key={level} onClick={() => setPermissionLevel(level.toString())}>
                                                        {level}
                                                    </Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Col>
                                </Row>
                                <Button variant="primary" type="submit" className="mt-3" disabled={isInviting}>
                                    {isInviting ? 'Inviting...' : 'Invite'}
                                </Button>
                            </Form>
                        </Tab>
                        <Tab eventKey="reset" title="Reset Password">
                            <Form onSubmit={handleResetPassword}>
                                <Form.Group controlId="currentPassword">
                                    <Form.Label>Current Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="Enter current password"
                                        value={currentPassword}
                                        onChange={(e) => setCurrentPassword(e.target.value)}
                                        required
                                    />
                                </Form.Group>
                                <Form.Group controlId="newPassword" className="mt-3">
                                    <Form.Label>New Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="Enter new password"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        required
                                    />
                                </Form.Group>
                                <Form.Group controlId="confirmPassword" className="mt-3">
                                    <Form.Label>Confirm New Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="Confirm new password"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        required
                                    />
                                </Form.Group>
                                <Button variant="primary" type="submit" className="mt-3" disabled={isResetting}>
                                    {isResetting ? 'Resetting...' : 'Reset Password'}
                                </Button>
                            </Form>
                        </Tab>
                    </Tabs>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onHide}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ProfileModal;