import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setClientArn, setAccountId } from 'src/redux/slices/awsAccountSlice';
import { awsService } from 'src/services/awsService';
import toast from 'react-hot-toast';

const useAwsAccount = () => {
    const dispatch = useDispatch();
    const token = useSelector(state => state.user.token);
    const [loading, setLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('Please wait...');
    const [linkText, setLinkText] = useState('');
    const [linkUrl, setLinkUrl] = useState('');

    const handleProceed = useCallback(async (clientArn) => {
        setLoading(true);
        setLoadingMessage('Verifying client ARN...');
        setLinkText('');
        setLinkUrl('');

        try {
            const addAccountResponse = await awsService.addAwsAccount(clientArn, token);
            if (!addAccountResponse || !addAccountResponse.aws_account_id) {
                throw new Error('AWS account ID not found in the response.');
            }

            setLoadingMessage('Client ARN verified. Click below to go to the dashboard.');
            setLinkText('Go to Dashboard');
            setLinkUrl('/');
            dispatch(setAccountId(addAccountResponse.aws_account_id));
            toast.success('Client ARN verified successfully');
        } catch (error) {
            console.error(error);
            if (error.response && error.response.data) {
                const errorMessage = Array.isArray(error.response.data.error) ? error.response.data.error.join(', ') : error.response.data.error;
                const errorCode = Array.isArray(error.response.data.code) ? error.response.data.code.join(', ') : error.response.data.code;
                setLoadingMessage(`Error: ${errorMessage} | Code: ${errorCode}. Please contact support with given error code(s).`);
            } else {
                setLoadingMessage(`An unexpected error occurred. Please contact support.`);
            }
            setLinkText('Contact Support');
            setLinkUrl('#'); // Placeholder link
        } finally {
            // setLoading(false);
        }
    }, [dispatch, token]);

    return {
        handleProceed,
        loading,
        loadingMessage,
        linkText,
        linkUrl
    };
};

export default useAwsAccount;
