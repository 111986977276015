import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Link } from 'react-router-dom';

const SidebarDashboard = (props) => {
    const [inactive, setInactive] = useState(false);
    const location = useLocation();

    useEffect(() => {
        if (inactive) {
            document.querySelectorAll(".sub-menu").forEach((el) => {
                el.classList.remove("active");
            });
        }
        props.onCollapse(inactive);

        // Determine active menu item based on the URL
        const currentPath = location.pathname;
        determineActiveMenuItem(currentPath);
    }, [inactive, props, location.pathname]);

    const setColN = (item) => {
        props.parentCallBack(item);
    }
    const determineActiveMenuItem = (currentPath) => {
        let activeMenuItem = 'd'; // Default to 'dashboard'
        if (currentPath.includes('/instancedetail')) {
            activeMenuItem = 'j';
        } else if (currentPath.includes('/groups')) {
            activeMenuItem = 'doc';
        }
        else if (currentPath.includes('/maintenance')) {
            activeMenuItem = 'a';
        }
        else if (currentPath.includes('/reports')) {
            activeMenuItem = 's';
        }

        props.parentCallBack(activeMenuItem);
    }
    return (
        <>
            <div className={`side-menu ${inactive ? "inactive" : ""}`}>
                <div ref={props.takeRef} onClick={() => setInactive(!inactive)} className="toggle-menu-btn">
                    {inactive ? (
                        <i className="bi bi-arrow-right-square"></i>
                    ) : (
                        <i className="bi bi-list"></i>
                    )}
                </div>
                <div className="main-menu">
                    <ul>
                        <li>
                            <Link to="" onClick={() => setColN('d')} className={`menu-item ${(props.isColActive === 'd') ? "active" : ""}`}>
                                <div className="menu-icon">
                                    <i className="bi bi-speedometer2"></i>
                                </div>
                                <span>Dashboard</span></Link>
                        </li>
                        <li>
                            <Link to="/instancedetail" onClick={() => setColN('j')} className={`menu-item ${(props.isColActive === 'j') ? "active" : ""}`}>
                                <div className="menu-icon">
                                    <i className="bi bi-briefcase"></i>
                                </div>
                                <span>Instances</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/reports" onClick={() => setColN('s')} className={`menu-item ${(props.isColActive === 's') ? "active" : ""}`}>
                                <div className="menu-icon">
                                    <i className="bi bi-sliders"></i>
                                </div>
                                <span>Reports</span>
                            </Link>
                        </li>

                    </ul>
                </div>
            </div>
        </>
    );
};
export default SidebarDashboard;