import React, { useState, useEffect } from 'react';
import {
    Box,
    Select,
    MenuItem,
    Paper,
    Typography,
    ToggleButton,
    ToggleButtonGroup,
    InputLabel,
    FormControl,
    LinearProgress
} from '@mui/material';
import { useSelector } from 'react-redux';
import { awsService } from 'src/services/awsService';
import Cost from './graphs/Cost';
import Utilization from './graphs/Utilization';

export default function Reports() {
    // State variables
    const [loadBalancers, setLoadBalancers] = useState([]);
    const [selectedLoadBalancer, setSelectedLoadBalancer] = useState('');
    const [awsmoGroupId, setAwsmoGroupId] = useState(null);
    const [selectedMetric, setSelectedMetric] = useState('cost');
    const [granularity, setGranularity] = useState('7d'); // New state for granularity
    const [loadingLBs, setLoadingLBs] = useState(false);

    // Redux selectors
    const token = useSelector((state) => state.user.token);
    const accountId = useSelector((state) => state.awsAccount.accountId);
    const region = useSelector((state) => state.awsAccount.region);

    // Fetch load balancers on component mount or when region changes
    useEffect(() => {
        // Reset state
        setLoadBalancers([]);
        setSelectedMetric('cost');
        setSelectedLoadBalancer('');
        setAwsmoGroupId(null);

        fetchLoadBalancers();
    }, [region]);

    // Fetch load balancers
    const fetchLoadBalancers = async () => {
        setLoadingLBs(true);
        try {
            const data = await awsService.fetchLoadBalancers(token, region);
            setLoadBalancers(data);
        } catch (error) {
            console.error('Error fetching load balancers:', error);
        } finally {
            setLoadingLBs(false);
        }
    };

    // Handle load balancer selection change
    const handleLoadBalancerChange = (e) => {
        const selectedId = e.target.value;
        setSelectedLoadBalancer(selectedId);

        const selectedLB = loadBalancers.find(lb => lb.id === selectedId);
        if (selectedLB) {
            setAwsmoGroupId(selectedLB.awsmo_group_id);
        } else {
            setAwsmoGroupId(null);
            console.warn('Selected Load Balancer not found in loadBalancers array.');
        }
    };

    // Find the selected load balancer object
    const selectedLoadBalancerObj = loadBalancers.find(lb => lb.id === selectedLoadBalancer);

    return (
        <Box className='margin-top-dash font-open' sx={{ overflowX: 'auto' }}>
            <Box className='h-scroller rightContainer'>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        p: 3,
                        bgcolor: '#f5f5f5',
                        minHeight: '100vh'
                    }}
                >
                    <Paper elevation={1} sx={{ p: 3, mb: 2 }}>
                        {/* Load Balancer selection */}
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                            <Typography variant="h6" sx={{ mr: 2, whiteSpace: 'nowrap' }}>
                                Load Balancer ARN :
                            </Typography>
                            <Box sx={{ width: '70%' }}>
                                <FormControl fullWidth>
                                    <InputLabel id="load-balancer-label">Load Balancer</InputLabel>
                                    <Select
                                        labelId="load-balancer-label"
                                        name="load_balancer_arn"
                                        label="Load Balancer"
                                        value={selectedLoadBalancer}
                                        onChange={handleLoadBalancerChange}
                                    >

                                        {loadingLBs ? (
                                            <MenuItem value="" disabled>
                                                <Box sx={{ width: '100%' }}>
                                                    <LinearProgress />
                                                </Box>
                                            </MenuItem>
                                        ) : loadBalancers.length > 0 ? (
                                            loadBalancers.map((lb) => (
                                                <MenuItem key={lb.id} value={lb.id}>
                                                    {lb.LoadBalancerArn}
                                                </MenuItem>
                                            ))
                                        ) : (
                                            <MenuItem value="" disabled>
                                                No load balancers available
                                            </MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>
                        {/* Metric selection */}
                        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                            <ToggleButtonGroup
                                value={selectedMetric}
                                exclusive
                                onChange={(e, newMetric) => {
                                    if (newMetric) {
                                        setSelectedMetric(newMetric);
                                    }
                                }}
                                aria-label="metric selection"
                            >
                                <ToggleButton value="cost" aria-label="monthly cost">
                                    Cost
                                </ToggleButton>
                                <ToggleButton value="utilization" aria-label="monthly utilization">
                                    Utilization
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Box>

                        {/* Granularity selection */}
                        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                            <ToggleButtonGroup
                                value={granularity}
                                exclusive
                                onChange={(e, newGranularity) => {
                                    if (newGranularity) {
                                        setGranularity(newGranularity);
                                    }
                                }}
                                aria-label="granularity selection"
                            >
                                <ToggleButton value="7d" aria-label="last 7 days">
                                    Last 7 days
                                </ToggleButton>
                                <ToggleButton value="1m" aria-label="last 1 month">
                                    Last 1 Month
                                </ToggleButton>
                                <ToggleButton value="1y" aria-label="last 1 year">
                                    Last 1 Year
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Box>

                        {/* Render either Cost or Utilization based on selectedMetric */}
                        {selectedMetric === 'cost' ? (
                            <Cost
                                awsmoGroupId={awsmoGroupId}
                                token={token}
                                loadBalancer={selectedLoadBalancerObj}
                                granularity={granularity}
                            />
                        ) : (
                            <Utilization
                                accountId={accountId}
                                token={token}
                                loadBalancer={selectedLoadBalancerObj}
                                granularity={granularity}
                            />
                        )}
                    </Paper>
                </Box>
            </Box>
        </Box>
    );
}
